import HomeHeader from '../components/home/HomeHeader';
import HomePieces from '../components/home/HomePieces';
import HomeCultural from '../components/home/HomeCultural';
import HomeBenefits from '../components/home/HomeBenefits'

function Home({isBreak, t}) {
  return (
    <>
      <HomeHeader isBreak={isBreak} t={t}/>
      <HomePieces isBreak={isBreak} t={t}/>
      <HomeCultural isBreak={isBreak} t={t}/>
      <HomeBenefits isBreak={isBreak} t={t}/>
    </>
  );
}

export default Home;
