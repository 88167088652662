import AboutAccordion from '../components/about/AboutAccordion';
import AboutHeader from '../components/about/AboutHeader';
import AboutProfiles from '../components/about/AboutProfiles';
import { Row, Container } from 'react-bootstrap';

function About({isBreak, t}) {
  return (
    <>
      <AboutHeader isBreak={isBreak} t={t} />
      <Container className="pt-5 about-vision-div">
        <Row>
            <div className="">
                <h2 className="about-subtitle">{t("about-h1-image-text")}</h2>
            </div>
        </Row>
      </Container>
      <AboutAccordion isBreak={isBreak} t={t} />
      <AboutProfiles isBreak={isBreak} t={t} />
    </>
  );
}

export default About;
