import { Row, Col } from 'react-bootstrap';

const BenefitsCarouselText = ({isBreak, thumbnail, text}) => {

    return (
        <Row>
            <Col md={4} className="d-none d-md-block">
                <img className="benefits-text-icon" src={thumbnail} alt=""/>
            </Col>
            <Col>
            <div className="align-text-icon">
                <img className="ps-2 pe-4 d-md-none" height="50" src={thumbnail} alt=""/>
                <p>{text}</p>
            </div>
            </Col>
        </Row>
    )
}

export default BenefitsCarouselText
