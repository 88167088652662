import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as RightArrow } from '../../static/icons/right-arrow.svg'
import { Link } from 'react-router-dom';

const FooterLeftLink = ({ text, link }) => {

    return (
        <Row className="mx-auto">
            <Col className="px-3 footer-link-pair">
                <Link to={link}> 
                    <div className="align-text-icon">
                        <h3 className="text-nowrap text-center">{text}</h3>
                        <RightArrow />
                    </div>
                </Link>
            </Col>
        </Row>
    )
}

export default FooterLeftLink

