import { Row, Container} from 'react-bootstrap';
import header_img from '../../static/images/about/aboutHeader.jpg';

const AboutHeader = ({ isBreak, t, style }) => {
    const styles = {
        backgroundImage:
        `url(` + header_img + `),
            linear-gradient(
            180deg,
            hsl(0deg 0% 60%) 0%,
            hsl(344deg 0% 64%) 21%,
            hsl(344deg 0% 69%) 30%,
            hsl(344deg 0% 73%) 39%,
            hsl(344deg 0% 77%) 46%,
            hsl(344deg 0% 82%) 54%,
            hsl(344deg 0% 86%) 61%,
            hsl(344deg 0% 91%) 69%,
            hsl(344deg 0% 95%) 79%,
            hsl(0deg 0% 100%) 100%
            )
        `,
        backgroundPosition: '60% 75%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'hsl(60, 0%, 60%)',
        backgroundBlendMode: 'multiply'
    }

    return (
        <>
        <div className="pt-5 about-header" style={styles}>
            <img src={header_img} style={{display: "none"}} />
            <Container className="px-5">
                <h1 className="about-title d-md-none">{t("about-h1-1")}</h1>
                <Row className="d-none d-md-block">
                    <div className="about-title-text mx-auto mpt-3 pb-3">
                        <h1 className="about-title pb-2">{t("about-h1-1")}</h1>
                        <p className="pb-2">{t("about-h1-text-1")}</p>
                        <p>{t("about-h1-text-2")}</p>
                    </div>
                </Row>
                <Row className="d-md-none pt-4">
                    <div className="about-title-text mx-auto mpt-3 pb-3">
                        <p>{t("about-h1-text-1")}</p>
                        <p>{t("about-h1-text-2")}</p>
                    </div>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default AboutHeader

