import { Dropdown } from 'react-bootstrap';
import i18next from 'i18next'
import DropDownToggle from './DropDownToggle';
import { ReactComponent as LanguageIcon}  from '../../static/icons/language.svg'
import { ReactComponent as FlagEs } from '../../static/icons/flag-es.svg' 
import { ReactComponent as FlagGb } from '../../static/icons/flag-gb.svg' 
import { ReactComponent as FlagSa } from '../../static/icons/flag-sa.svg' 
import { ReactComponent as FlagFr } from '../../static/icons/flag-fr.svg' 

const DropdownLanguages = ({languages, className, currentLanguage}) => {

    const flags = {
        "gb" : <FlagGb />,
        "es" : <FlagEs />,
        "sa" : <FlagSa />,
        "fr" : <FlagFr />  
    }

    return (
        <Dropdown className={className}>
            <Dropdown.Toggle as={DropDownToggle}>
                <LanguageIcon id="language-button"/>
            </Dropdown.Toggle>
            <Dropdown.Menu align={currentLanguage.dir === 'rtl' ? "start" : "end"} >
                {languages.map(({code, name, country_code}) =>
                    <Dropdown.Item key={country_code} onClick={() => i18next.changeLanguage(code)} >
                        <span className={`flag-icon mx-2`}>
                            {flags[country_code]}
                        </span>
                        {name}
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropdownLanguages