const DonateButton = ({ t, className }) => {

    return (
        <button 
            className={"donate-button " + className} 
            type="button" 
            onClick={(e) => {e.preventDefault();window.open("https://www.gofundme.com/f/m3rrvn-the-road", "_blank");}}
        >
            {t("nav-donate")}
        </button>
    )
}

export default DonateButton

