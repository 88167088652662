import { Stack } from 'react-bootstrap';

const TeamIconButton = ({ name, image, setActive, num }) => {

    return (    
        <>
        <Stack align="center" gap={2}>
            <div>
                <input className="team-icon" type="image" src={image} alt={name} onClick={() => setActive(num)}/>
            </div>
            <p>{name}</p>
        </Stack>
        </>
    )
}

export default TeamIconButton

