import { Row, Col, Container } from 'react-bootstrap';
import FooterLeft from './FooterLeft'
import FooterRight from './FooterRight';
import footerLeftImage from '../../static/images/footer-left.jpg'

const Footer = ({ t, languages }) => {

    const image = {
        backgroundImage: "url(" + footerLeftImage + ")",
    }

    return (
        <>
        <Container fluid className="pt-5 mxw-1920" id="footer">
            <Row style={{color: "white"}}>
                <Col xs={12} md={6} className="px-0 footer-content" id="footer-left-image" style={image}>
                    <FooterLeft t={t}/>
                </Col>
                <Col xs={12} md={6} className="px-0 pb-4 footer-content" id="footer-right">
                    <FooterRight t={t} languages={languages}/>
                </Col>
            </Row>
            <Row>
                <Container className="py-3" id="footer-bottom"/>
            </Row>
        </Container>
        </>
    )
}

export default Footer
