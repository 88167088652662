import { Card } from 'react-bootstrap';

const BasicCard = ({image, title, text, className}) => {

    return (
        <Card className={"px-3 py-3 basic-card " + className}>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{text}</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default BasicCard

