import { Row, Container, Col} from 'react-bootstrap';
import { useState } from 'react';
import TeamIconButton from '../shared/TeamIconButton';
import TeamIconText from '../shared/TeamIconText';
import vicky from '../../static/images/about/vicky.jpg'
import antoine from '../../static/images/about/antoine.jpg'
import liz from '../../static/images/about/liz.jpg'
import bamba from '../../static/images/about/bamba.jpg'
import { boldSubstring } from '../shared/helperFunctions'



const AboutProfiles = ({ isBreak, t }) => {


    const [active, setActive] = useState(3);

    return (
        <>
        <Container className="pt-5 about-icons-div">
            <h2 className="about-subtitle">{t("about-h2-our-team")}</h2>
            <Row className="pt-4">
                <Col>
                    <TeamIconButton 
                        name={t("about-p-3-name")} 
                        image={vicky} 
                        setActive={setActive} num={3}
                    />
                </Col>
                <Col>
                    <TeamIconButton 
                        name={t("about-p-2-name")} 
                        image={liz} 
                        setActive={setActive} num={2}
                    />
                </Col>
                <Col>
                    <TeamIconButton 
                        name={t("about-p-1-name")} 
                        image={antoine} 
                        setActive={setActive} num={1}
                    />
                </Col>
                <Col>
                    <TeamIconButton 
                        name={t("about-p-4-name")} 
                        image={bamba} 
                        setActive={setActive} num={4}
                    />
                </Col>
            </Row>
        </Container>
        <div className="profile-container pt-4">
            <Container className={`${active === 1 ? "profile active" : "profile"}`}>
                <TeamIconText 
                            name={t("about-p-1-name")} 
                            title={t("about-p-1-title")} 
                            text1={t("about-p-1-text-1")}
                            text2={t("about-p-1-text-2")} 
                            image={antoine}
                    />
            </Container>
            <Container className={`${active === 2 ? "profile active" : "profile"}`}>
                <TeamIconText 
                            name={t("about-p-2-name")} 
                            title={t("about-p-2-title")} 
                            text1={boldSubstring(t("about-p-2-text-1"), "THE ROAD")}
                            text2={boldSubstring(t("about-p-2-text-2"), "THE ROAD")} 
                            image={liz}
                    />
            </Container>
            <Container className={`${active === 3 ? "profile active" : "profile"}`}>
                <TeamIconText 
                            name={t("about-p-3-name")} 
                            title={t("about-p-3-title")} 
                            text1={boldSubstring(t("about-p-3-text-1"), "THE ROAD")}
                            text2={t("about-p-3-text-2")} 
                            image={vicky}
                    />
            </Container>
            <Container className={`${active === 4 ? "profile active" : "profile"}`}>
                <TeamIconText 
                            name={t("about-p-4-name")} 
                            title={t("about-p-4-title")} 
                            text1={boldSubstring(t("about-p-4-text-1"), "THE ROAD")}
                            text2={boldSubstring(t("about-p-4-text-2"), "THE ROAD")} 
                            image={bamba}
                    />
            </Container>
        </div>
        </>
    )
}

export default AboutProfiles


