import { Row, Container, Col} from 'react-bootstrap';
import ContactForm from './ContactForm';
import ContactSocialSide from './ContactSocialSide';

const ContactMain = ({ isBreak, t }) => {

    return (
        <>
        <Container className="pt-5 contact-header">
            <h1>{t("footer-contact")}</h1>
            <p className="d-md-none pt-2">{t("contact-self-details")}</p>
            <div className="black-line d-md-none mb-3"></div>
            <Row>
                <Col className="mpt-4 d-none d-md-block">
                    <ContactSocialSide isBreak={isBreak} t={t}/>
                </Col>
                <Col>
                    <ContactForm isBreak={isBreak} t={t}/>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default ContactMain

