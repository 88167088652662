import { Container, Row, Col, Stack} from 'react-bootstrap';
import DonateButton from '../shared/DonateButton';
import { boldSubstring } from '../shared/helperFunctions'

/*
"donate-h1-1" : "Support Us",
"donate-text-1" : "Although we have a big vision, THE ROAD is still in its early days of construction. We are hoping to launch this project with the help of the community.",
"donate-text-2" : "We are raising €20,000 for:",
"donate-text-3-ul-1" : "Logo, website and brand identity",
"donate-text-3-ul-2" : "The development of version one of THE ROAD’s Platform",
"donate-text-3-ul-3" : "Funds for the rent and creation of the Cultural Workspace",
"donate-text-4" : "Please consider contributing to this important cause. We would love to add you to the of donors on our website.",
"donate-button-subtext" : "Thank you in advance for kickstarting a project that will empower those who have left their home country in search of new opportunities and make a positive global impact surrounding the concept of migration.",
"donate-button-text"

*/

const DonateMain = ({ isBreak, t }) => {

    return (
        <>
        <Container className="pt-5" id="support">
            <Container className="support-main-card ps-5 pe-5 pt-4 pb-5">
                <h1>{t("donate-h1-1")}</h1>
                <Row className="pt-3">
                    <Col>
                        <p>{boldSubstring(t("donate-text-1"), "THE ROAD")}</p>
                        <p>{t("donate-text-2")}</p>
                        <ul>
                            <li><p>{t("donate-text-3-ul-1")}</p></li>
                            <li><p>{boldSubstring(t("donate-text-3-ul-2"), "THE ROAD")}</p></li>
                            <li><p>{t("donate-text-3-ul-3")}</p></li>
                        </ul>
                        <p>{t("donate-text-4")}</p>
                    </Col>
                </Row>
                <Row>
                    <Stack gap={3}>
                        <div className="mx-auto">
                            <DonateButton t={t}/>
                        </div>
                        <p className="donateButtonSubtext">{t("donate-button-subtext")}</p>
                    </Stack>
                </Row>
            </Container>
        </Container>
        </>
    )
}

export default DonateMain

