import { createRef } from "react";
import { useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { Form, Button }  from 'react-bootstrap';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";


const ContactForm = ({ isBreak, t }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState('false');
    const recaptchaRef = createRef();

    const submitForm = (e) => {
        e.preventDefault()
        clearForm()
        setSuccess('true')
        verifyAndSend()
    }

    const verifyAndSend = async () => {
        const token = await recaptchaRef.current.executeAsync();
        if(token) {
            sendMail()
        }
    }

    const sendMail = () => { 
        axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        { 
            service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
            template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            user_id: process.env.REACT_APP_EMAILJS_USER_ID,
            template_params: { 
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phoneNumber,
                language: t("language"),
                message: message
            }
        })
        .then(function (response) {
            console.log(response);
          })
        .catch(function (error) {
        console.log(error);
        });
    }
    const clearForm = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhoneNumber('')
        setMessage('')
    }

    return (
        <>
        <Row className={"h-100 success-message-" + success + " "}> 
            <Col className="my-auto">
                <Alert variant="success" className="mx-auto w-50">
                    {t("contact-success-message")}
                </Alert>
            </Col>
        </Row>
        <Form className={"form-" + success + " "}  onSubmit={submitForm}>
        <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
            />
            <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>{t("contact-f-name") + " *"}</Form.Label>
                <Form.Control 
                    required
                    value={firstName} 
                    onChange ={(e) => setFirstName(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>{t("contact-l-name") + " *"}</Form.Label>
                <Form.Control 
                    required
                    value={lastName} 
                    onChange ={(e) => setLastName(e.target.value)}
                    />
            </Form.Group>


            <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>{t("contact-email") + " *"}</Form.Label>
                <Form.Control 
                    required
                    value={email} 
                    type="email"
                    onChange ={(e) => setEmail(e.target.value)}
                    />
                <Form.Text className="text-muted">
                    {t("contact-email-message")}
                </Form.Text>
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="formPhoneNumber">
                <Form.Label>{t("contact-number")}</Form.Label>
                <PhoneInput
                    className="form-control"
                    value={phoneNumber}
                    onChange={setPhoneNumber}/>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formMessage">
                <Form.Label>{t("contact-message") + " *"}</Form.Label>
                <Form.Control 
                    as="textarea"
                    rows="4"
                    required
                    value={message} 
                    onChange ={(e) => setMessage(e.target.value)}
                    />
            </Form.Group>
            <Button variant="primary" type="submit">
                {t("contact-submit")}
            </Button>
        </Form>
        </>
    )
}

export default ContactForm

