import { Row, Container, Col} from 'react-bootstrap';
import underConstruction from '../../static/images/under-construction.png'
import { boldSubstring } from '../shared/helperFunctions'

const PlatformHeader = ({ isBreak, t }) => {

    return (
        <Container className="platform-header pt-5">
            <Row>
                <Col> 
                    <h1>{t("platform-h1-1")}</h1>
                    <p className="pt-2">{boldSubstring(t("platform-h1-1-text-1"), "THE ROAD")}</p>
                </Col>
            </Row>
            <Row>
                <Col className="pt-5">
                    <img width={500} className="mx-auto d-block img-fluid" src={underConstruction} alt="Under Construction" />
                    <h3 className="pt-3">{t("platform-construction")}</h3>
                </Col>
            </Row>
        </Container>
    )
}

export default PlatformHeader

