import { Row, Col, Container } from 'react-bootstrap';
import BasicCard from '../shared/BasicCard'
import ScrollBasicCard from '../shared/ScrollBasicCard';
import { boldSubstring } from '../shared/helperFunctions'

import hangout from '../../static/svgs/hangout.svg'
import hq from '../../static/svgs/hq.svg'
import kitchen from '../../static/svgs/kitchen.svg'

import card_img from '../../static/images/workspace-card-image.png'



const WorkspaceCards = ({isBreak, t}) => {

    var key = 0;

    const generateKey = (x) => {
        key = key + 1
        return key
    }

    const cards = [
        [
            <BasicCard image={hq} title={t("cult-card-1-title")} text={boldSubstring(t("cult-card-1-text"), "THE ROAD")} className="card-hq"/>,
            <BasicCard image={hangout} title={t("cult-card-8-title")} text={boldSubstring(t("cult-card-8-text"), "THE ROAD")} className="card-hangout"/>,
            <BasicCard image={kitchen} title={t("cult-card-5-title")} text={t("cult-card-5-text")} className="card-kitchen"/>
        ],
        [
            <BasicCard image={card_img} title={t("cult-card-2-title")} text={boldSubstring(t("cult-card-2-text"), "THE ROAD")} className="card-platform"/>,
            <BasicCard image={card_img} title={t("cult-card-4-title")} text={t("cult-card-4-text")} className="card-workshop"/>,
            <BasicCard image={card_img} title={t("cult-card-6-title")} text={t("cult-card-6-text")} className="card-storage"/>,
        ],
        [
            <BasicCard image={card_img} title={t("cult-card-7-title")} text={t("cult-card-7-text")} className="card-classrooms"/>,
            <BasicCard image={card_img} title={t("cult-card-3-title")} text={t("cult-card-3-text")} className="card-mentorship"/>,
        ]
    ]

    const fullWidthCards = () => cards.map(function (nested) {
        return <Row key={generateKey()} className="pt-5"> {nested.map(function (element) {
            return <Col md={{span: 4}} key={generateKey()}>{element}</Col>;
        })} </Row>
    });

    return (
        <Container>
            {isBreak ? fullWidthCards() : <ScrollBasicCard cards={cards}/>}
        </Container>

    )
}

export default WorkspaceCards
