import { Row, Container, Col, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import InterconnectedImage from '../../static/images/home/interconnected-platform.jpg'
import { boldSubstring } from '../shared/helperFunctions'

const HomePieces = ({ isBreak, t }) => {

    return (
        <>
        <Container className="home-pieces platform">
            <Row className="pt-4 pb-4 align-items-center home-sub-header">
                <div className={`pb-3 d-flex justify-content-center`}>
                    <h1 className="lh-1">THE ROAD</h1>
                </div>
                <div className="header-text-wrap mx-auto">
                    {/*<p className="lh-1 header-text">{t("home1-h1-1-text-1")}</p>*/}
                    <p className="lh-1 header-text">{boldSubstring(t("home1-h1-1-text-1"), "THE ROAD")}</p>
                    <p className="lh-1 header-text">{t("home1-h1-1-text-2")}</p>
                </div>
            </Row>
            <Row className="pt-4 align-items-center">
                <Col>
                    <div className={`d-flex justify-content-${isBreak ? "left" : "center"}`}>
                        <h1 className="lh-1">{t("home2-h2-1")}</h1>
                    </div>
                </Col>
            </Row>
            <div className="home-pieces-colored">
                <Row className="pt-5 align-items-center d-none d-md-block">
                    <Col className="d-flex justify-content-left">
                        <h2 className="lh-1">{t("home2-h3-1")}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="d-flex justify-content-left mpt-2">
                        <Image src={InterconnectedImage} fluid/>
                    </Col>
                    <Col className="d-md-none pt-3">
                        <h2 className="lh-1">{t("home2-h3-1")}</h2>
                    </Col>
                    <Col md={6} className="pt-2">
                        <p>{t("home2-h3-1-text-1")}</p>
                        <Link className="ms-auto" to="/platform">
                            {t("home2-h3-1-button")}&nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                        </Link>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )

}

export default HomePieces