import { Container } from 'react-bootstrap';
import WorkspaceHeader from '../components/workspace/WorkspaceHeader';
import WorkspaceCards from '../components/workspace/WorkspaceCards';

function Workspace( {isBreak, t} ) {

  return (
    <>
      <Container className="pt-5" id="workspace">
        <WorkspaceHeader isBreak={isBreak} t={t}/>
        <WorkspaceCards isBreak={isBreak} t={t}/>
      </Container>
    </>
  );
}

export default Workspace;
