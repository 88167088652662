import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import PartnersImage from '../../static/images/home/benefits-partners.jpg'
import GovernmentImage from '../../static/images/home/benefits-government.jpg'
import DonorsImage from '../../static/images/home/benefits-donors.jpg'
import MigrantsImage from '../../static/images/home/benefits-migrants.jpg'

import Migrant1 from '../../static/icons/home/Migrant 1.svg';
import Migrant2 from '../../static/icons/home/Migrant 2.svg';
import Migrant3 from '../../static/icons/home/Migrant 3.svg';
import Migrant4 from '../../static/icons/home/Migrant 4.svg';

import Donor1 from '../../static/icons/home/Donor 1.svg';
import Donor2 from '../../static/icons/home/Donor 2.svg';
import Donor3 from '../../static/icons/home/Donor 3.svg';
import Donor4 from '../../static/icons/home/Donor 4.svg';

import Org1 from '../../static/icons/home/Org 1.svg';
import Org2 from '../../static/icons/home/Org 2.svg';
import Org3 from '../../static/icons/home/Org 3.svg';


import Gov1 from '../../static/icons/home/Gov 1.svg';
import Gov2 from '../../static/icons/home/Gov 2.svg';
import Gov3 from '../../static/icons/home/Gov 3.svg';

import BenefitsCarouselItem from './BenefitsCarouselItem';
import { insertLinksOnSubstrings } from '../shared/helperFunctions'


SwiperCore.use([Navigation, Pagination]);

const BenefitsCarousel = ({isBreak, t}) => {

    return (
        <>
            <Swiper
                loop
                autoplay
                navigation
                pagination
            >
                <SwiperSlide>
                        <div className="ci-benefits mps-3" dir={t("language")==="ar" ? "rtl" : ""}>
                            <BenefitsCarouselItem isBreak={isBreak} title={t("home3-h3-1")} image={MigrantsImage}
                                thumbnailsNtext = {
                                    [
                                        [Migrant1, t("home3-h3-1-text-1"), 0],
                                        [Migrant2, t("home3-h3-1-text-2"), 1],
                                        [Migrant3, t("home3-h3-1-text-3"), 2],
                                        [Migrant4, t("home3-h3-1-text-4"), 3],
                                    ]
                                }
                            />
                        </div>
                </SwiperSlide>
                <SwiperSlide>
                        <div className="ci-benefits mps-3" dir={t("language")==="ar" ? "rtl" : ""}>
                            <BenefitsCarouselItem isBreak={isBreak} title={t("home3-h3-2")} image={PartnersImage}
                                thumbnailsNtext = {
                                    [
                                        [Org1, t("home3-h3-2-text-1"), 4],
                                        [Org2, t("home3-h3-2-text-2"), 5],
                                        [Org3, t("home3-h3-2-text-3"), 6],
                                        [Migrant2, t("home3-h3-2-text-4"), 7],
                                    ]
                                }
                            />
                        </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="ci-benefits mps-3" dir={t("language")==="ar" ? "rtl" : ""}>
                        <BenefitsCarouselItem isBreak={isBreak} title={t("home3-h3-3")} image={GovernmentImage}
                            thumbnailsNtext = {
                                [
                                    [Gov1, t("home3-h3-3-text-1"), 8],
                                    [Gov2, t("home3-h3-3-text-2"), 9],
                                    [Donor1, t("home3-h3-3-text-4"), 10],
                                    [Gov3, t("home3-h3-3-text-3"), 11],
                                ]
                            }
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                        <div className="ci-benefits mps-3" dir={t("language")==="ar" ? "rtl" : ""}>
                            <BenefitsCarouselItem isBreak={isBreak} title={t("home3-h3-4")} image={DonorsImage}
                                thumbnailsNtext = {
                                    [
                                        [Donor1, insertLinksOnSubstrings(t("home3-h3-4-text-1"), "https://unric.org/en/sdg-8/", "https://unric.org/en/sdg-10/", "8", "10"), 12],
                                        [Donor2, t("home3-h3-4-text-2"), 13],
                                        [Donor3, t("home3-h3-4-text-3"), 14],
                                        [Donor4, t("home3-h3-4-text-4"), 15],
                                    ]
                                }
                            />
                        </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default BenefitsCarousel

