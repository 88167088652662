import { Row } from 'react-bootstrap';
import { useHorizontalScroll } from "./useHorizontalScroll";

const ScrollBasicCard = ({cards}) => {
    const scrollRef = useHorizontalScroll();

    const allCards = cards.map(function (nested) {
        return nested.map(function (element) {
            return <div className="card-sm"> {element} </div>
        })
    });
    

    return (
        <>
        <Row>
            <div ref={scrollRef} className="scrollcards">
                {allCards}
            </div>
        </Row>
        </>
    )
}

export default ScrollBasicCard