import Container from 'react-bootstrap/Container';
import ContactMain from '../components/contact/ContactMain';

function Contact({isBreak, t}) {
  return (
    <>
      <Container>
        <ContactMain isBreak={isBreak} t={t}/>
      </Container>
    </>
  );
}

export default Contact;
