import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import i18next from 'i18next'
import {ReactComponent as LogoWhite} from '../../static/icons/logo-white.svg'
import Socials from '../shared/Socials';


const FooterRight = ({ t, languages }) => {

    return (
        <>
        <Container className="ps-5">
            <Row className="py-4">
                <Col>
                    <LogoWhite />
                </Col>
            </Row>
            <Row className="pt-3">
                <Col>
                    <Row className="pb-2">
                        <h3>{t("footer-company")}</h3>
                    </Row>
                    <Row>
                        <Col className="text-nowrap footer-links">
                            <Link to='/platform'><p className="footer-link">{t("footer-platform")}</p></Link>
                            <Link to='/workspace'><p className="footer-link">{t("footer-workspace")}</p></Link>
                            <Link to='/about'><p className="footer-link">{t("footer-about")}</p></Link>
                        </Col>
                        <Col className="text-nowrap footer-links">
                            <Link to='/contact'><p className="footer-link">{t("footer-contact")}</p></Link>
                            <Link to='/support'><p className="footer-link">{t("footer-support")}</p></Link>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1} id="black-line">
                </Col>
                <Col>
                    <Row>
                        <h3>{t("footer-languages")}</h3>
                    </Row>
                    <Row>
                    <Col className="text-nowrap footer-links">
                        <a href="#">
                            <p className="footer-link" onClick={() => i18next.changeLanguage(languages[0].code)}>{languages[0].name}</p>
                        </a>
                        <a href="#">
                            <p className="footer-link" onClick={() => i18next.changeLanguage(languages[1].code)}>{languages[1].name}</p>
                        </a>
                    </Col>
                    <Col className="text-nowrap footer-links">
                        <a href="#">
                            <p className="footer-link language-link" onClick={() => i18next.changeLanguage(languages[2].code)}>{languages[2].name}</p>
                        </a>
                        <a href="#">
                            <p className="footer-link language-link" onClick={() => i18next.changeLanguage(languages[3].code)}>{languages[3].name}</p>
                        </a>
                    </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="pt-4">
                    <h3>{t('footer-social')}</h3>
                <Socials className="pt-2" id="footer-icon-container"/>
            </Row>
        </Container>
        </>
    )
}

export default FooterRight