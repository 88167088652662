import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from "react";
import cookies from 'js-cookie'
import About from './About';
import Home from './Home';
import Platform from './Platform'
import Contact from './Contact'
import Workspace from './Workspace'
import Donate from './Donate'
import NavMenu from '../components/navbar/NavMenu';
import useWindowDimensions from '../components/shared/useWindowDimensions';
import Footer from '../components/footer/Footer';
import ScrollToTop from "../components/shared/ScrollToTop";

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb'
  },
  {
    code: 'es',
    name: 'Español',
    country_code: 'es'
  },
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'ar',
    name: 'العربية',
    country_code: 'sa',
    dir: 'rtl'
  },
]

function App() {
  const { t } = useTranslation()
  //const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguageCode = t("language")
  const currentLanguage = languages.find(l => l.code === currentLanguageCode)

  const { height, width } = useWindowDimensions();
  const isBreak = () => {if (width > 768) { return true }} 
  const isLarge = () => {if (width > 992) {return true}}

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage])

  return (
    <>
      <Router>
        <ScrollToTop />          
        <NavMenu t={t} languages={languages} currentLanguage={currentLanguage}/>
        <Routes>
          <Route path="/" element={<Home isBreak={isBreak()} t={t} />}/>
          <Route path="/about" element={<About isBreak={isBreak()} t={t}/>}/>
          <Route path="/platform" element={<Platform isBreak={isBreak()} t={t}/>}/>
          <Route path="/contact" element={<Contact isBreak={isBreak()} t={t}/>}/>
          <Route path="/workspace" element={<Workspace isBreak={isLarge()} t={t}/>}/>
          <Route path="/support" element={<Donate isBreak={isBreak()} t={t}/>}/>
          <Route path="*" element={<Home isBreak={isBreak()} t={t} />}/>
        </Routes>
        <Footer isBreak={isBreak()} t={t} languages={languages}/>
      </Router>
    </>
  );
}

export default App;
