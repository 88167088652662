function boldSubstring(string, substring) {
    let pos = string.search(substring)
    var content = string
    if (pos !== -1) {
        const first = string.slice(0, pos)
        const second = string.slice(pos + substring.length)
        content = <p>{first}<strong>{substring}</strong>{second}</p>
    } 

    return content
}


function insertLinksOnSubstrings(string, link1, link2, sub1, sub2, boldRoad=false) {
    let pos1 = string.search(sub1)
    if (pos1 !== -1) {
        const first = string.slice(0, pos1)
        const second = string.slice(pos1 + sub1.length)
        let pos2 = second.search(sub2)
        if (pos2 !== -1) {
            const third = second.slice(0, pos2)
            var fourth = second.slice(pos2 + sub2.length)
            if (boldRoad) { 
                fourth = boldSubstring(fourth, "THE ROAD")
            }
            var content = <p>{first}<a href="!#" onClick={(e) => {e.preventDefault();window.open(link1, "_blank");}}>{sub1}</a>{third}<a href="!#" onClick={(e) => {e.preventDefault();window.open(link2, "_blank");}}>{sub2}</a>{fourth}</p>
            return content
        }
    } 
    return string
}

export {boldSubstring, insertLinksOnSubstrings}