import BenefitsCarouselText from "./BenefitsCarouselText";
import { Container, Row, Col, Stack, Image} from 'react-bootstrap';

const BenefitsCarouselItem = ({isBreak, title, image, thumbnailsNtext}) => {

    const key = 0

    const content = thumbnailsNtext.map((item) =>
        <BenefitsCarouselText isBreak={isBreak} thumbnail={item[0]} text={item[1]} key={item[2]}/>
    );

    return (
        <Container className="mpt-4 sps-0 spe-0">
            <Row>
                <Col md={6} className="mmb-5 smb-3">
                    <h2 className="d-none d-md-block">{title}</h2>
                    <div className="text-overlay-div">
                        <div className="image-overlay">
                            <img className="d-block w-100 co-img" src={image} alt="Second slide"/>
                        </div>
                        <div className="ci-overlay d-md-none">{title}</div>
                    </div>
                </Col>
                <Col md={6} className="my-auto carouseltext sps-4 spe-4">
                    <Stack gap={isBreak ? 3 : 0}>
                        {content}
                    </Stack>
                </Col>
            </Row>
        </Container>
    )
}

export default BenefitsCarouselItem