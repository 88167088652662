import {Row, Col, Container, Stack} from 'react-bootstrap'

const TeamIconText = ({name, title, text1, text2, image}) => {

    return (
        <Container className="py-3 team-icon-text">
            <Row className="pt-3 ps-4 team-icon-text-header">
                <Col xs="auto" className="mx-auto">
                    <img src={image} alt= {name + " Profile Image"}/>
                </Col>
                <Col className="my-auto d-md-none">
                    <Stack>
                        <h3>{name}</h3>
                        <p>{title}</p>
                    </Stack>
                </Col>
            </Row>
            <div className="d-none d-md-block pt-3">
                <Row>
                    <Stack align="center">
                        <h3>{name}</h3>
                        <p>{title}</p>
                    </Stack>
                </Row>
                <Row>
                    <Container className="px-5 pt-1">
                        <p>{text1}</p>
                        <p>{text2}</p>
                    </Container>
                </Row>
            </div>
            <Row className="d-md-none pt-4 ps-4">
                <p>{text1}</p>
                <p>{text2}</p>
            </Row>
        </Container>
    )
}

export default TeamIconText