
const HamButton = ({className, target, onClick, expanded}) => {

    return (
        <button className={className} id="nav-hamburgButton" type="button" data-toggle="collapse" data-target={"#" + target} aria-controls={target} aria-expanded="false" aria-label="Toggle navigation" onClick={onClick}>
            <div id="nav-hamburgIcon" className={expanded ? "open" : null}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </button>
    )
}

export default HamButton