import DonateMain from '../components/donate/DonateMain';

function Donate({isBreak, t}) {
  return (
    <>
      <DonateMain isBreak={isBreak} t={t}/>
    </>
  );
}

export default Donate;
