import { Row, Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderImage from '../../static/images/home/header-image.jpg'


const HomeHeader = ({ isBreak, t }) => {

    return (
        <>
        <Container className="p-none home-header mxw-1920">
            <div className="text-overlay-div">
                <video className="w-100 home-video" poster={HeaderImage} autoPlay muted loop>
                    <source src="https://headervideotheroad.s3.eu-central-1.amazonaws.com/header-video.mp4" type="video/mp4"/>
                </video>
                <div className="video-overlay">
                    <h1>{t("home1-h1-1")}</h1>
                    <div /*className="d-none d-sm-block" */ >
                        <Link to="/platform">
                            <button><span>{t("Start Journey")}</span></button>
                        </Link>
                    </div>
                </div>
            </div>
        </Container>
        <Container>
            
        </Container>
        {/*
        <Container className="home-header">
            <Row className="pt-4 align-items-center">
                <Col md={{order:2, span:6}} className="d-flex justify-content-center no-padding">
                    <div className="text-overlay-div">
                        <video className="w-100" src={HeaderVideo} autoPlay muted loop/>
                        <div className="hi-overlay d-md-none">{t("home1-h1-1")}</div>
                    </div>
                </Col>
                <Col md={{order:1, span:6}} className="pt-4 justify-content-left">
                    <h1 className="lh-2 d-none d-md-block">{t("home1-h1-1")}</h1>
                    <p>{t("home1-h1-1-text-1")}</p>
                    <p>{t("home1-h1-1-text-2")}</p>
                    <Container className="d-flex">
                        <button className={`m${isBreak ? "s" : "x"}-auto home-scheduleCall ps-5 pe-5`}>{t("home1-h1-button")}</button>
                    </Container>
                </Col>
            </Row>
        </Container>
        */}
        </>
    )
}

export default HomeHeader

