import { Link, NavLink } from 'react-router-dom';
import { Nav, Navbar, Container} from 'react-bootstrap';
import { useState } from 'react';
import HamButton from '../shared/HamburgerButton';
import DropDownLanguages from '../shared/DropDownLanguages'
import DonateButton from '../shared/DonateButton';
import { ReactComponent as LogoBlack } from '../../static/icons/logo-black.svg'


const NavMenu = ({t, languages, currentLanguage}) => {
    const [expanded, setExpanded] = useState(false);
    const onClick = () => {setExpanded(!expanded)}

    return (
        <>
        <Navbar expand="lg" expanded={expanded} sticky="top">
            <Container className={"navbar-" + t("language") + " "}>
                <HamButton className="d-lg-none" target="navbar-nav" onClick={onClick} expanded={expanded}/>
                <Navbar.Brand as={Link} to="/" className="mx-auto"><LogoBlack /></Navbar.Brand>
                <DropDownLanguages languages={languages} className="d-none d-sm-block d-lg-none pe-2 language-button" currentLanguage={currentLanguage}/>
                <DonateButton t={t} className="d-lg-none donate-button-mobile" />
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="xlps-7 mps-3" onSelect={() => setExpanded(false)}>
                        <Nav.Item className="nav-Item xlps-7">
                            <NavLink className="nav-link" to="/" onClick={() => setExpanded(false)}>{t("nav-home")}</NavLink>
                        </Nav.Item>
                        <Nav.Item className="nav-Item">
                            <NavLink className="nav-link" to="/about" onClick={() => setExpanded(false)}>{t("nav-about")}</NavLink>
                        </Nav.Item>
                        <Nav.Item className="nav-Item">
                            <NavLink className="nav-link" to="/workspace" onClick={() => setExpanded(false)}>{t("nav-pieces-workspace")}</NavLink>
                        </Nav.Item>
                        <Nav.Item className="nav-Item">
                            <NavLink className="nav-link" to="/platform" onClick={() => setExpanded(false)}>{t("nav-pieces-platform")}</NavLink>
                        </Nav.Item>
                        <Nav.Item className="nav-Item">
                            <NavLink className="nav-link" to="/support" onClick={() => setExpanded(false)}>{t("nav-support")}</NavLink>
                        </Nav.Item>
                        {/*<Nav.Item className="nav-Item d-sm-none">
                            <DropDownLanguages languages={languages} className="pe-2" currentLanguage={currentLanguage}/>
                        </Nav.Item>*/}
                    </Nav>
                </Navbar.Collapse>
                <DropDownLanguages languages={languages} className="d-none d-lg-block pe-2 language-button" currentLanguage={currentLanguage}/>
                <DonateButton t={t} className="d-none d-lg-block"/>
                
            </Container>
        </Navbar>
        </>
    );
}

export default NavMenu


// Header.propTypes = {
//     title: PropTypes.string.isRequired
// }

/* <Navbar bg="light" expand="sm">
<Container>
    <Navbar.Brand as={Link} to="/">
        The Road
    </Navbar.Brand>
    <Navbar.Toggle/>
    <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
            <Nav.Item>
                <Nav.Link as={Link} to="/">Home</Nav.Link>
            </Nav.Item>
        </Nav>
    </Navbar.Collapse>
</Container>
</Navbar> */

// CSS In js
// const headingStyle = {
//     color: 'red'
// }