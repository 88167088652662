import { Row, Col } from 'react-bootstrap';
import Instagram from '../../static/icons/instagram.svg';
import Linkedin from '../../static/icons/linkedin.svg';
import Facebook from '../../static/icons/facebook.svg';

const Socials = ({ className, id }) => {


    return (
        <>
        <Row className={className} id={id}>  
            <Col>
                <a href="!#" onClick={(e) => {e.preventDefault();window.open("https://www.instagram.com/theroadngo/", "_blank");}}>
                    <img src={Instagram} id={id + "-insta "} alt="Instagram Icon"/>
                </a>
            </Col>
            <Col>
                <a href="!#" onClick={(e) => {e.preventDefault();window.open("https://www.linkedin.com/company/the-road-ngo/", "_blank");}}>
                    <img src={Linkedin} id={id + "-linkedin "} alt="Linkedin Icon"/>
                </a>
            </Col>
            <Col>
                <a href="!#" onClick={(e) => {e.preventDefault();window.open("https://www.facebook.com/The-Road-108380355161497", "_blank");}}>
                    <img src={Facebook} id={id + "-linkedin "} alt="Linkedin Icon"/>
                </a>
            </Col>
        </Row>
        </>
    )
}

export default Socials

