import { Accordion, Container} from 'react-bootstrap';
import { boldSubstring, insertLinksOnSubstrings } from '../shared/helperFunctions'

const AboutAccordion = ({ isBreak, t }) => {

    return (
        <>
        <Container className="pt-4 about-accordion">
            <Accordion >
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t("about-drop-title-1")}</Accordion.Header>
                    <Accordion.Body>{t("about-drop-title-1-text")}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t("about-drop-title-2")}</Accordion.Header>
                    <Accordion.Body>
                        {insertLinksOnSubstrings(t("about-drop-title-2-text-1"), "https://unric.org/en/sdg-8/", "https://unric.org/en/sdg-10/", "8", "10", true)}
                        {/*{boldSubstring(t("about-drop-title-2-text-1"), "THE ROAD")} */}
                        <p></p>
                        {t("about-drop-title-2-text-2")}
                        <p></p>
                        {boldSubstring(t("about-drop-title-2-text-3"), "THE ROAD")}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
        </>
    )
}

export default AboutAccordion


