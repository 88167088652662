import { Container, Row, Col, Stack } from 'react-bootstrap';
import FooterLeftLink from './FooterLeftLink';

const FooterLeft = ({ t }) => {


    return (
        <>
        <Container>
            <Row>
                <Col>
                    <Stack gap={4}>
                        <FooterLeftLink text={t("footer-support")} link="/support"/>
                        <FooterLeftLink text={t("footer-partner")} link="/contact"/>
                    </Stack>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default FooterLeft

