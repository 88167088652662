import PlatformHeader from "../components/platform/PlatformHeader";

function Platform({isBreak, t}) {
  return (
    <>
      <PlatformHeader isBreak={isBreak} t={t} />
    </>
  );
}

export default Platform;
