import { Row, Col, Stack} from 'react-bootstrap';
import Socials from '../shared/Socials';
import { ReactComponent as LocationPin } from '../../static/icons/location-pin.svg';
import { ReactComponent as Phone } from '../../static/icons/phone.svg';
import { ReactComponent as Envelope } from '../../static/icons/envelope.svg';
import { ReactComponent as WhatsApp } from '../../static/icons/whatsapp.svg';

const ContactSocialSide = ({ t }) => {

    return (
        <>
        <p>{t("contact-self-details")}</p>
        <Stack className="pt-3 contact-stack">
            <Row className="mx-4">
                <Col xs="auto">
                    <LocationPin />
                </Col>
                <Col>
                    <p>{t("contact-self-address")}</p>
                </Col>
            </Row>
            <Row className="mx-4">
                <Col xs="auto">
                    <Envelope />
                </Col>
                <Col>
                    <p>contact@theroad.org</p>
                </Col>
            </Row>
            <Row className="mx-4">
                <Col xs="auto">
                    <Phone />
                </Col>
                <Col>
                    <p>{t("contact-self-number")}</p>
                </Col>
            </Row>
            <Row className="mx-4">
                <Col xs="auto">
                    <WhatsApp />
                </Col>
                <Col>
                    <p>{t("contact-self-whatsapp")}</p>
                </Col>
            </Row>
        </Stack>
        <h1 className="pt-5">{t("contact-follow-us")}</h1>
        <Socials className="contact-socials-custom" />
        </>
    )
}

export default ContactSocialSide

