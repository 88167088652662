import { Row, Container, Col} from 'react-bootstrap';
import BenefitsCarousel from '../shared/BenefitsCarousel'

const HomeBenefits = ({ isBreak, t }) => {

    return (
        <Container className="home-benefits">
            <Row className="pt-4 d-flex justify-content-center" id="home-benefits-title"> 
                <Col className={`d-flex justify-content-${isBreak ? "left" : "center"}`} md={{ span: 16}} lg={{ span : 10}}>
                    <h2>{t("home3-h2-1")}</h2>
                    <h1>{t.language}</h1>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col md={{ span: 16}} lg={{span : 10}}>
                    <BenefitsCarousel isBreak={isBreak} t={t}/>
                </Col>
            </Row>
        </Container>
    )
}


export default HomeBenefits

