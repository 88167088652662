import { Row, Container } from 'react-bootstrap';
import { boldSubstring } from '../shared/helperFunctions'

const WorkspaceHeader = ({isBreak, t}) => {

    return (
        <Container>
            <Row>
                <h1>{t("cult-h1-1")}</h1>
            </Row>
            <Row className="pt-3">
                <p>{boldSubstring(t("cult-h1-text-1"), "THE ROAD")}</p>
                <p>{t("cult-h1-text-2")}</p>
            </Row>
        </Container>
    )
}

export default WorkspaceHeader
